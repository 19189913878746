import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Animated, Easing, Image, StyleSheet, View } from "react-native";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import alert from "../components/CustomAlert";
import { addValueToInteractions } from "../functions/user-interaction-handler";
import getWidth from "../hooks/getWidth";

export default function TannenbaumWheelScreen() {
  const [rotation, setRotation] = useState(new Animated.Value(0));
  const [resultText, setResultText] = useState("");
  const navigation = useNavigation();

  useEffect(() => {
    const timer = setTimeout(() => {
      spinWheel();
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const spinWheel = () => {
    const randomValue = Math.random();
    let finalRotation = 0;
    let result = "No Prize";

    if (randomValue <= 0.00025) {
      // Grand Prize
      finalRotation = -90;
      result = "Grand Prize";
    } else if (randomValue <= 0.0001) {
      // Normal Prize
      finalRotation = 175;
      result = "Normal Prize";
    } else {
      // No Prize
      finalRotation = 35;
    }

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tannenbaum_wheel_spun",
      "TannenbaumWheelScreen",
      "spun",
      { result }
    );

    // Animate rotation
    Animated.timing(rotation, {
      toValue: 7200 + finalRotation, // Spin several times before landing
      duration: 10000,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    }).start(async () => {
      rotation.setValue(finalRotation); // Set final position
      setResultText(result);

      // Action after animation finishes
      if (result === "Grand Prize" || result === "Normal Prize") {
        setTimeout(() => {
          alert(
            "Herzlichen Glückwunsch! 🎁 🎉",
            "In kürze erhältst du deinen Gewinn via Mail. Vielen Dank fürs Mitmachen und weiterhin viel Freude mit der MEDICEO App! 🎄✨",
            [
              {
                text: "Zurück zu MEDICEO",
                style: "default",
                onPress: () => {
                  navigation.goBack();
                },
              },
            ]
          );
        }, 2000);
      } else {
        setTimeout(() => {
          alert(
            "Leider kein Gewinn 🫤 – aber schlauer als vorher! 🌟🎁 !",
            "Manchmal lernt man mehr, als man gewinnt. Danke, dass du mitgemacht hast – und denk dran: Wissen ist das beste Geschenk, das du dir selbst machen kannst! ",
            [
              {
                text: "Zurück zu MEDICEO",
                style: "default",
                onPress: () => {
                  navigation.goBack();
                },
              },
            ]
          );
        }, 2000);
      }
      await addValueToInteractions("did_tannenbaum", "true");
    });
  };

  const interpolateRotation = rotation.interpolate({
    inputRange: [0, 360],
    outputRange: ["0deg", "360deg"],
  });

  return (
    <View style={styles.container}>
      <View style={styles.wheelContainer}>
        <Image
          source={require("../../assets/images/tannenbaum/wheel_pin.png")}
          style={styles.pin}
        />
        <Animated.Image
          source={require("../../assets/images/tannenbaum/wheel.png")}
          style={[
            styles.wheel,
            { transform: [{ rotate: interpolateRotation }] },
          ]}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#333",
  },
  wheelContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  wheel: {
    width: getWidth() - 100,
    aspectRatio: 1,
    height: "auto",
  },
  pin: {
    width: 50,
    height: 50,
    marginBottom: 12,
  },
});
