import getColorScheme from "../hooks/useColorScheme";
import { useNavigation } from "@react-navigation/native";
import Colors from "../constants/static-colors";
import { isWebAndNotMobile } from "../hooks/isLargerDevice";
import { Image, ScrollView, Text, View } from "react-native";
import { useEffect, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";
import staticColors from "../constants/static-colors";
import apiCall from "../api/api-call";
import { loadPushNotifications } from "../api/content/load-all/load-all-content";
import { useDispatch } from "react-redux";
import { setUnreadedNotificationCount } from "../functions/data/actions";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const NotificationScreen = () => {
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    async function loadNotification() {
      const notifications = await loadPushNotifications();
      const sortedNotifications = notifications?.sort((a, b) => {
        return new Date(b.sent_at) - new Date(a.sent_at);
      });
      setNotifications(sortedNotifications);
      dispatch(setUnreadedNotificationCount(0));
      AsyncStorage.setItem(
        "readedNotificationsCount",
        notifications.length?.toString()
      );
    }
    loadNotification();
  }, []);

  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].background,
        flex: 1,
        paddingHorizontal: isWebAndNotMobile() ? 32 : 10,
      }}
    >
      <ScrollView contentContainerStyle={{ paddingTop: 12 }}>
        {notifications?.map((pn, index) => (
          <NotificationItem key={index} pn={pn} />
        ))}
      </ScrollView>
    </View>
  );
};

const NotificationItem = ({ pn }: { pn: any }) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const colorScheme = getColorScheme();
  const date = new Date(pn?.sent_at);
  const germanFormattedDate = date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <TouchableOpacity
      style={{
        padding: 8,
        paddingVertical: 15,
        borderRadius: 10,
        backgroundColor: isCollapsed ? Colors[colorScheme].accent : "white",
        borderLeftColor: "red",
        borderLeftWidth: 3,
        marginBottom: 30,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.35,
        shadowRadius: 3.84,
        paddingHorizontal: 14,
      }}
      onPress={() => setCollapsed(!isCollapsed)}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ fontSize: 16, fontWeight: "700" }}>{pn?.title}</Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text style={{ marginRight: 8, fontSize: 12, fontWeight: 200 }}>
            {germanFormattedDate}
          </Text>
          <Feather
            name={isCollapsed ? "chevron-down" : "chevron-up"}
            size={24}
            color={staticColors[getColorScheme()].text}
          />
        </View>
      </View>
      {isCollapsed && (
        <View style={{ marginTop: 10, display: "flex", flexDirection: "row" }}>
          <Text style={{ fontSize: 14 }}>{pn?.body}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
};
