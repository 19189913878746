import { Image, Text, TouchableOpacity, View } from "react-native";

import { useEffect, useState } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import CustomModal from "./CustomModal";
import { useNavigation } from "@react-navigation/native";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../functions/user-interaction-handler";
import { useSelector } from "react-redux";
import { selectAllContent } from "../../functions/data/actions";

export default function TannanbaumInfoModal({ open, onClose }) {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);
  const allData = useSelector(selectAllContent);

  async function setTannenbaumData() {
    const pages = allData?.pages.filter(
      (page) =>
        page.internal_rating > 2 &&
        page.content?.path?.length > 0 &&
        page.content_type_id !== "scorePage" &&
        page.content_type_id !== "nodePage"
    );
    let randomPage;
    let randomBox;

    do {
      randomPage = pages[Math.floor(Math.random() * pages.length)];
      randomBox = getRandomBoxId(randomPage.content);
    } while (!randomBox);

    await addValueToInteractions("tannenbaum_box", randomBox);
    await addValueToInteractions("tannenbaum_page", randomPage._id);
  }

  function getRandomBoxId(pageContent) {
    // List of keys to exclude
    const excludedKeys = [
      "detailPath",
      "path",
      "sources",
      "appendedSources",
      "tags",
      "multiContentSectionType",
    ];

    // Helper function to recursively traverse the content and collect boxes with valid ids
    function collectBoxesWithIds(content, boxes = []) {
      if (Array.isArray(content)) {
        content.forEach((item) => collectBoxesWithIds(item, boxes));
      } else if (typeof content === "object" && content !== null) {
        Object.entries(content).forEach(([key, value]) => {
          // Skip keys in the excluded list
          if (excludedKeys.includes(key)) {
            return;
          }
          if (key === "id") {
            boxes.push(content); // Only push the object if it has a valid "id" key
          }
          collectBoxesWithIds(value, boxes);
        });
      }
      return boxes;
    }

    // Collect all valid boxes with ids
    const validBoxes = collectBoxesWithIds(pageContent);

    // If no valid boxes are found, return null
    if (validBoxes.length === 0) {
      console.warn("No valid boxes with ids found in the content.");
      return null;
    }

    // Select a random box
    const randomIndex = Math.floor(Math.random() * validBoxes.length);
    const randomBox = validBoxes[randomIndex];

    return randomBox.id;
  }

  async function continueAction() {
    setLoading(true);
    const tannenbaumPage = getValueFromInteractions("tannenbaum_box");
    if (!tannenbaumPage) await setTannenbaumData();
    setLoading(false);
    onClose();
  }

  useEffect(() => {
    if (open)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "tannenbaum_info_modal_presented",
        "TannenbaumInfoModal",
        "presented"
      );
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Image
          source={require("../../../assets/images/tannenbaum/vouchers.png")}
          style={{
            width: 150,
            height: 150,
            alignSelf: "center",
            marginBottom: 12,
          }}
        />
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          Kleine Weihnachtsauszeit? 💸🍕🍔🍝
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            color: Colors[colorScheme].text,
          }}
        >
          Genieße Dinner @home mit Wolt-Gutscheinen oder erfülle dir deine
          Wünsche mit Amazon! {"\n\n"}Prüfe dein medizinisches Wissen beim
          Entdecken unserer Inhalte 🔍 Auf einer unserer Seiten versteckt sich
          ein Tannenbaum 🎄– finde ihn und sicher dir sofort die Chance auf
          einen Gutschein deiner Wahl: {"\n\n"}25€ oder 50€ Wolt-Gutschein für
          Dinner @home 🍔 {"\n"}25€ oder 50€ Amazon-Gutschein für deine Wünsche
          🎁
        </Text>

        <DefaultButton
          action={continueAction}
          style={{ marginTop: 20 }}
          text="Jetzt teilnehmen"
          icon={null}
        />
        <TouchableOpacity
          onPress={() => {
            onClose();
            navigation.navigate("WebBrowserScreen", {
              url: "https://firebasestorage.googleapis.com/v0/b/medify-826de.appspot.com/o/Teilnahmebedingungen%20Gewinnspiel%20Weihnachten.pdf?alt=media&token=f75a4886-151f-4014-97dc-d8523d1f458a",
              title: "Teilnahmebedingungen Gewinnspiel Weihnachten",
            });
          }}
        >
          <Text
            style={{
              fontSize: 12,
              color: Colors[colorScheme].text,
              textAlign: "center",
              textDecorationLine: "underline",
              marginTop: 20,
            }}
          >
            Zu den Teilnahmebedingungen
          </Text>
        </TouchableOpacity>
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].accent,
        ...style,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "500" }}>{text}</Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
