import apiCall, { getURL } from "../../../../api/api-call";
import { store } from "../../../../functions/store";
import { setUser } from "../../../../functions/user/functions";
import { continueWithUser } from "../../LoginScreen";
import * as Keychain from "react-native-keychain";

export async function sendAuthCodeToServer(answer, navigation, user_type) {
  console.log("ANSWER", answer);
  const response = await apiCall("/api/v4/auth/google", {
    ...answer,
    user_type,
  });
  console.log("RESPONSE", response);
  return response;
  // APPLE: familyName, givenName, namePrefix

  if (response?.status === 200) {
    if (response.data.firstname) {
      const _response = await setUser(response.data);
      if (_response.valid) {
        continueWithUser(
          _response.userData,
          _response.valid,
          false,
          response.data.email,
          "",
          navigation,
          true,
          response.data.auth
        );
      }
    }
  }

  // Verwende das zurückgegebene Passwort, um ein neues JWT-Token zu bekommen → auth/token/refresh
}

export async function authenticateWithGoogle({
  access_token,
  expires_in,
  token_type,
  scope,
  id_token,
}) {
  try {
    const response = await apiCall("/api/v4/auth/google", {
      access_token: access_token,
      expires_in: expires_in,
      token_type: token_type,
      scope: scope,
      id_token: id_token,
    });
    console.log("response", response);
    if (response?.data?.access_token && response?.data?.refresh_token) {
      await store.dispatch(
        setSessionToken(response.data.access_token, response.data.refresh_token)
      );
    }

    return response;
  } catch (error) {
    console.error("Google Authentication failed", error);
    return null;
  }
}

export async function refreshToken() {
  const tokens = await getStoredTokens();
  if (!tokens || !tokens.refreshToken) return null;

  try {
    const response = await apiCall("/api/v4/auth/refresh", {
      refresh_token: tokens.refreshToken,
    });

    if (response?.data?.access_token) {
      await store.dispatch(
        setSessionToken(response.data.access_token, tokens.refreshToken)
      );
      return response.data.access_token;
    }
  } catch (error) {
    console.error("Failed to refresh Google token", error);
  }

  return null;
}

// Tokens speichern
export const setSessionToken =
  (accessToken, refreshToken) => async (dispatch) => {
    if (refreshToken) {
      await Keychain.setGenericPassword(
        "auth",
        JSON.stringify({ accessToken, refreshToken })
      );
    }
    dispatch({ type: "SET_SESSION_TOKEN", payload: accessToken });
  };

export const getStoredTokens = async () => {
  const credentials = await Keychain.getGenericPassword();
  return credentials ? JSON.parse(credentials.password) : null;
};

export const clearTokens = async () => {
  await Keychain.resetGenericPassword();
};

export async function refreshAccessToken() {
  const tokens = await getStoredTokens();
  if (!tokens || !tokens.refreshToken) return null;

  try {
    const response = await fetch(getURL() + "/api/v4/auth/refresh", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh_token: tokens.refreshToken }),
    });

    if (!response.ok) {
      await clearTokens();
      return null;
    }

    const data = await response.json();
    if (!data.access_token) return null;

    await store.dispatch(
      setSessionToken(data.access_token, tokens.refreshToken)
    );
    return data.access_token;
  } catch (error) {
    console.error("Token refresh failed", error);
    return null;
  }
}
