import { Feather, FontAwesome5 } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../locales/i18n";
import { loginCall } from "../../api/api-call";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import RatingModal from "../../components/modals/RatingModal";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";

import getColorScheme from "../../hooks/useColorScheme";

import alert from "../../components/CustomAlert";

import AsyncStorage from "@react-native-async-storage/async-storage";
import loadAllData from "../../api/content/load-all/load-all-content";
import { InlineNotificationType } from "../../components/Notifications/NotificationComponent";
import ChangeFontSizeModal from "../../components/modals/ChangeFontSizeModal";
import { showInlineNotification } from "../../entry/Root";
import configureAnimations from "../../functions/configure-animations";
import { selectEnterprise } from "../../functions/enterprise/actions";
import {
  selectShowTermsAndConditions,
  setCurrentTabSelection,
  setShowTermsAndConditions,
} from "../../functions/navigation/actions";
import { logout } from "../../functions/profile/functions";
import { addValueToInteractions } from "../../functions/user-interaction-handler";
import {
  selectCurrentUser,
  setCurrentUser,
} from "../../functions/user/actions";
import { User } from "../../functions/user/types";
import {
  isInformationMissingDoc,
  isInformationMissingNurse,
  isInformationMissingStudent,
} from "./helpers";
import UserComponent from "./profile/UserComponent";
import { Badge } from "react-native-paper";
import BadgeComponent from "../../components/BadgeComponent";
import {
  selectShowBadge,
  selectUnreadedNotificationCount,
  setShowBadge,
} from "../../functions/data/actions";

export const showMissingProfileInformation = (currentUser) => {
  if (currentUser?.user_type === "student")
    return isInformationMissingStudent(currentUser);
  if (currentUser?.user_type === "doc" || currentUser?.user_type === "doctor")
    return isInformationMissingDoc(currentUser);

  if (currentUser?.user_type === "nurse")
    return isInformationMissingNurse(currentUser);

  return false;
};

export async function reloadUser(currentUser, dispatch) {
  const response = await loginCall(
    currentUser.password,
    currentUser.email.toLowerCase().trim()
  );
  const userData = response?.userData as User;
  if (!userData) return;
  dispatch(setCurrentUser(userData));
}

export default function MoreScreen() {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [fontSizeModalVisible, setFontSizeModalVisible] = useState(false);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const showBadge = useSelector(selectShowBadge);
  const unreadedNotificationCounter = useSelector(
    selectUnreadedNotificationCount
  );
  const { height, width } = useWindowDimensions();
  const [refreshing, setRefreshing] = useState(false);
  const [reloading, setReloading] = useState(false);

  useEffect(() => {
    navigation.addListener("focus", () => {
      dispatch(setCurrentTabSelection("Mehr"));
    });
  }, []);

  const onRefresh = async () => {
    setRefreshing(true);
    setReloading(true);
    await reloadUser(currentUser, dispatch);
    setReloading(false);
    setTimeout(() => {
      setRefreshing(false);
    }, 1250);
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <ChangeFontSizeModal
        open={fontSizeModalVisible}
        onClose={() => setFontSizeModalVisible(false)}
      />

      <RatingModal
        open={ratingModalVisible}
        onClose={() => setRatingModalVisible(false)}
      />
      {refreshing && Platform.OS !== "android" && (
        <View
          style={{
            position: "absolute",
            top: 20,
            opacity: 0.5,
            zIndex: 10,
            alignSelf: "center",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {reloading ? (
            <ActivityIndicator />
          ) : (
            <Text
              style={{
                color: Colors[colorScheme].text,
                fontSize: 14,
                marginRight: 8,
              }}
            >
              Gerade aktualisiert
            </Text>
          )}
          {!reloading && (
            <Ionicons
              name="checkmark-circle"
              size={20}
              color={Colors[colorScheme].text}
            />
          )}
        </View>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        onScrollBeginDrag={() => {
          if (refreshing) {
            setRefreshing(false);
          }
        }}
        contentContainerStyle={{
          padding: Platform.OS === "web" ? 20 : 0,
          paddingVertical: 20,
        }}
        refreshControl={
          <RefreshControl
            tintColor="transparent"
            colors={["transparent"]}
            style={{ backgroundColor: "transparent" }}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
      >
        <NewTermsComponent />

        <UserComponent currentUser={currentUser} />

        {(currentUser.user_type === "dev" || __DEV__) && (
          <SearchSeparator text={"Environment verwalten"} />
        )}
        {(currentUser.user_type === "dev" || __DEV__) && <SwitchEnvironment />}
        <SearchSeparator text={i18n.t("account_settings")} />
        <View>
          <ListItem
            title="Benachrichtigung"
            action={() => navigation.navigate("NotificationScreen")}
            icon={<Feather name="bell" size={24} color="gray" />}
          />
          {unreadedNotificationCounter > 0 && (
            <BadgeComponent
              style={{ top: 0, right: width - 35 }}
              text={unreadedNotificationCounter}
              size={22}
            />
          )}
        </View>
        <ListItem
          title={i18n.t("my_account")}
          action={() => navigation.navigate("AccountScreen")}
          icon={<Feather name="user" size={24} color="gray" />}
        />

        <ListItem
          title="Rechtliches"
          action={() => navigation.navigate("LegalScreen")}
          icon={<Feather name="book-open" size={24} color="gray" />}
        />
        {Platform.OS !== "web" && (
          <ListItem
            title="Benachrichtigungen verwalten"
            action={() =>
              navigation.navigate("PushNotificationScreen", {
                isFromSettings: true,
              })
            }
            icon={<Feather name="bell" size={24} color="gray" />}
          />
        )}
        {
          <ListItem
            title="Schriftgröße anpassen"
            action={() => setFontSizeModalVisible(true)}
            icon={<Ionicons name="text-outline" size={24} color="gray" />}
          />
        }
        <SearchSeparator text="Hilfe & Support" />
        {/* <ListItem
          title="Die Autoren von MEDICEO"
          action={() => navigation.navigate("AuthorsScreen")}
          icon={<Ionicons name="people-outline" size={24} color="gray" />}
        /> */}
        {
          <View>
            <ListItem
              title="Update History"
              action={() => {
                navigation.navigate("SoftChangeLogScreen");
                dispatch(setShowBadge(false));
              }}
              icon={<Ionicons name="time-outline" size={24} color="gray" />}
            />
            {showBadge && (
              <BadgeComponent
                style={{
                  right: 8,
                  top: 0,
                }}
                text="1"
              />
            )}
          </View>
        }
        <ListItem
          title="Tipps"
          action={() => navigation.navigate("TippsScreen")}
          icon={<Ionicons name="bulb-sharp" size={24} color="gray" />}
        />
        <ListItem
          title="Gebrauchsanweisung"
          action={() =>
            navigation.navigate("WebBrowserScreen", {
              url: "https://firebasestorage.googleapis.com/v0/b/medify-826de.appspot.com/o/01_LA-TPL-02-DE_Template-IFU-MDR.pdf?alt=media&token=3d8f269b-be4e-4e9e-9ca6-a1bfe8ed14fb",
              title: "Gebrauchsanweisung",
            })
          }
          icon={<Ionicons name="desktop-outline" size={24} color="gray" />}
        />

        <ListItem
          title="Kontaktiere uns"
          action={() =>
            navigation.navigate("WebBrowserScreen", {
              url: "https://form.typeform.com/to/moJc6seq",
              title: "MEDICEO Kontaktieren",
            })
          }
          icon={<Feather name="message-square" size={24} color="gray" />}
        />
        {false && (
          <ListItem
            title="Bewerte uns"
            action={() => setRatingModalVisible(true)}
            icon={
              <Ionicons name="logo-apple-appstore" size={24} color="gray" />
            }
          />
        )}
        <SearchSeparator text="Mehr von MEDICEO" />
        <ListItem
          title="Instagram"
          action={() =>
            navigation.navigate("WebBrowserScreen", {
              url: "https://instagram.com/heymediceo",
              title: "Instagram",
            })
          }
          icon={<Ionicons name="logo-instagram" size={24} color="gray" />}
        />
        <ListItem
          title="Website"
          action={() =>
            navigation.navigate("WebBrowserScreen", {
              url: "https://www.mediceo.com",
              title: "MEDICEO",
            })
          }
          icon={<Ionicons name="globe-outline" size={24} color="gray" />}
        />
        <ListItem
          title="TikTok"
          action={() =>
            navigation.navigate("WebBrowserScreen", {
              url: "https://www.tiktok.com/@mediceo.com",
              title: "TikTok",
            })
          }
          icon={<FontAwesome5 name="tiktok" size={24} color="gray" />}
        />
        <ListItem
          title="Jobs"
          action={() =>
            navigation.navigate("WebBrowserScreen", {
              url: "https://www.mediceo.com/karriere",
              title: "Jobs",
            })
          }
          icon={<Ionicons name="laptop-outline" size={24} color="gray" />}
        />
        <SignOutButton currentUser={currentUser} />
      </ScrollView>
    </View>
  );
}

const environments = ["dev", "staging", "prod"];
const customerEnvironments = ["dev", "prod"];

/**
 * Renders a switch environment component that allows the user to switch between development and production environments.
 * The component displays a row of buttons representing each environment, and updates the selected environment based on user interaction.
 * It also loads the previously selected environment from AsyncStorage and updates the state accordingly.
 */
const SwitchEnvironment = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isDevUser = currentUser?.user_type === "dev" || __DEV__;

  const selectedEnterprise = useSelector(selectEnterprise);
  const isDevCustomer = selectedEnterprise?.dev_content_access;

  const [environment, setEnvironment] = useState(isDevUser ? "dev" : "prod");
  const colorScheme = getColorScheme();

  const [loading, setLoading] = useState(false);

  function loadEnvironment() {
    AsyncStorage.getItem("environment").then((data) => {
      if (data) {
        setEnvironment(JSON.parse(data));
      }
    });
  }

  const handlePress = async (newEnvironment) => {
    AsyncStorage.setItem("environment", JSON.stringify(newEnvironment));
    setEnvironment(newEnvironment); // Update the state to immediately reflect the change
    setLoading(true);
    await loadAllData(selectedEnterprise?.internal_customer_id);
    setLoading(false);

    showInlineNotification({
      text: "Die Umgebung wurde erfolgreich geändert",
      type: InlineNotificationType.SUCCESS,
    });
  };

  useEffect(() => {
    loadEnvironment();
  }, []);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
        margin: 16,
        marginTop: 0,
      }}
    >
      {(isDevCustomer ? customerEnvironments : environments).map((env) => (
        <TouchableOpacity
          disabled={loading || environment === env}
          key={env}
          style={{
            padding: 16,
            opacity: loading ? 0.5 : 1,
            borderRadius: 8,
            flex: 1,
            margin: 4,
            backgroundColor:
              environment === env
                ? Colors[colorScheme].accent
                : Colors[colorScheme].foreground,
            alignItems: "center",
          }}
          onPress={() => handlePress(env)}
        >
          <Text
            style={{
              fontSize: Sizes.boxText + 1,
              fontWeight: environment === env ? "bold" : "400",
              color:
                environment === env
                  ? Colors["light"].text
                  : Colors[colorScheme].text,
            }}
          >
            {env.toUpperCase()}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const NewTermsComponent = () => {
  const shouldShowTermsAndConditionsHint = useSelector(
    selectShowTermsAndConditions
  );
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const colorScheme = getColorScheme();

  if (!shouldShowTermsAndConditionsHint) return;

  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].red,
        padding: 12,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomColor: Colors[colorScheme].background,
        borderBottomWidth: 2,
      }}
    >
      <View style={{ flex: 1 }}>
        <Text
          style={{
            fontWeight: "bold",
            flex: 1,
            color: "white",
          }}
        >
          Wir haben unsere Allgemeinen Geschäftsbedingungen (AGB´s) angepasst
        </Text>
        <TouchableOpacity
          style={{
            backgroundColor: "darkred",
            padding: 8,
            paddingHorizontal: 20,
            borderRadius: 8,
            marginTop: 8,
            alignItems: "center",

            flexDirection: "row",
            justifyContent: "space-between",
          }}
          onPress={async () => {
            configureAnimations();
            dispatch(setShowTermsAndConditions(false));
            await addValueToInteractions("show_terms_hint", "false");
            navigation.navigate("TermsAndConditionsScreen");
          }}
        >
          <Text style={{ color: "white" }}>Jetzt ansehen</Text>
          <Feather name="arrow-right" size={24} color="white" />
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        onPress={async () => {
          configureAnimations();
          await addValueToInteractions("show_terms_hint", "false");
          dispatch(setShowTermsAndConditions(false));
        }}
        style={{ marginLeft: 20 }}
      >
        <Feather name="x" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );
};

const ListItem = ({
  title,
  icon,
  action,
}: {
  title: string;
  icon: React.ReactNode;
  action?: () => void;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        padding: 16,
        paddingVertical: 16,
        borderRadius: 8,
        alignSelf: "stretch",
        alignItems: "center",
        margin: 16,
        marginVertical: 6,
        backgroundColor: Colors[colorScheme].foreground,
      }}
      onPress={action}
    >
      {icon}
      <Text
        style={{
          marginLeft: 20,
          flex: 1,
          fontSize: Sizes.boxText + 1,
          fontWeight: "400",
          color: Colors[colorScheme].text,
        }}
      >
        {title}
      </Text>

      <Feather name="chevron-right" size={15} color={"#666"} />
    </TouchableOpacity>
  );
};

const SignOutButton = ({ currentUser }) => {
  const navigation = useNavigation();
  return (
    <DefaultButton
      style={{
        margin: 16,
      }}
      textStyle={{ fontSize: 13 }}
      title="Abmelden"
      type={ButtonTypes.Destruktive}
      action={() => logoutAction(navigation, currentUser)}
    />
  );
};

const logoutAction = (navigation, currentUser) => {
  // if (Platform.OS === "web") {
  //   posthog?.reset();
  //   store.dispatch({ type: "RESET_STATE" });
  //   navigation.reset({
  //     index: 0,
  //     routes: [{ name: "LandingScreen" }],
  //   });
  //   logout(currentUser);
  //   return;
  // }
  alert("Abmelden", "Wirklich abmelden?", [
    {
      text: "Nein",
      style: "cancel",
    },
    {
      text: "Abmelden",
      style: "destructive",
      onPress: () => {
        logout(currentUser, navigation);
      },
    },
  ]);
};

const SearchSeparator = ({ text }) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        marginTop: 20,
        paddingHorizontal: 16,
        marginVertical: 6,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: "bold",
          color: Colors[colorScheme].text,
          opacity: 0.75,
          textTransform: "uppercase",
        }}
      >
        {text}
      </Text>
    </View>
  );
};
