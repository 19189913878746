import { Ionicons } from "@expo/vector-icons";
import * as Google from "expo-auth-session/providers/google";
import * as AuthSession from "expo-auth-session";
import { useEffect, useState } from "react";
import { CustomSignInButton } from "../LandingScreen";
import { authenticateWithGoogle } from "../functions/ProviderSignIn";
import i18n from "../../../../../locales/i18n";

export function SignInWithGoogleButton() {
  const [loading, setLoading] = useState(false);
  const [request, response, promptAsync] = Google.useAuthRequest({
    // Configure the Google endpoint
    iosClientId:
      "7305114246-utnp025rfmg9gotab793ijakf08k8nm7.apps.googleusercontent.com",
    webClientId:
      "7305114246-g16fhrp0b8k3oqb2i6kbm4n04l8pfka8.apps.googleusercontent.com", //TODO: WEB CLIENT ID

    scopes: [
      "openid",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ],
    redirectUri: AuthSession.makeRedirectUri(),
    // Add a default redirect URI (you can update it as per your needs)
  });
  console.log("Redirect URI:", AuthSession.makeRedirectUri());
  useEffect(() => {
    if (response?.type === "success") {
      const { access_token, expires_in, token_type, scope, id_token } =
        response.params;
      console.log(scope);
      authenticateWithGoogle(
        access_token,
        expires_in,
        token_type,
        scope,
        id_token
      );
    }
  }, [response]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      if (request) {
        await promptAsync();
      } else {
        console.log("Google authentication request is not ready.");
      }
    } catch (e) {
      console.log("Something went wrong with Google auth", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomSignInButton
      loading={loading}
      provider={<Ionicons name="logo-google" size={20} color="black" />} // Assuming you have an appropriate icon for Google
      text={i18n.t("continue_with_google")}
      onPress={handleGoogleSignIn}
    />
  );
}

// "7305114246-utnp025rfmg9gotab793ijakf08k8nm7.apps.googleusercontent.com",
