import Ionicons from "@expo/vector-icons/Ionicons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";

import { Platform, RefreshControl, ScrollView, Text, View } from "react-native";
import DeviceInfo from "react-native-device-info";
import { useDispatch, useSelector } from "react-redux";
import loadAllData from "../../../api/content/load-all/load-all-content";

import handleOneTimeAction from "../../../functions/one-time-action-handler";
import {
  addToUserCreationObject,
  selectCurrentUser,
} from "../../../functions/user/actions";
import getColorScheme from "../../../hooks/useColorScheme";

import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import FullScreenProgressBar from "../../../components/SkeletonLoadingAnimation";
import { RecommendationSection } from "../../../components/home-tab/RecommendationSection";

import { useFeatureFlagWithPayload } from "posthog-react-native";
import semver from "semver";
import DefaultActivityIndicator from "../../../components/DefaultActivityIndicator";
import DefaultHeader from "../../../components/header/DefaultHeaderComponents";
import { CustomCMSSection } from "../../../components/home-tab/CustomCMSSection";
import { RecentlyUsedSection } from "../../../components/home-tab/RecentlyUsedSection";
import UserSourceModal from "../../../components/modals/UserSourceModal";
import PrimeSection from "../../../components/partnerships/PrimeSection";
import SatelliteSection from "../../../components/partnerships/SatteliteSection";
import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { getResultPagesCount } from "../../../functions/current-result/helpers";
import {
  selectAllContent,
  selectIsLoading,
  selectVersionRequirements,
} from "../../../functions/data/actions";
import { selectEnterprise } from "../../../functions/enterprise/actions";
import {
  selectNavigationIntent,
  setCurrentTabSelection,
  setNavigationIntent,
  setShowNavbar,
} from "../../../functions/navigation/actions";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import { getValueFromInteractions } from "../../../functions/user-interaction-handler";
import isLargerDevice from "../../../hooks/isLargerDevice";
import useGreeting from "../../../hooks/useGreeting";
import useScrollTopMargin from "../../../hooks/useScrollTopMargin";
import useShowContentPartner from "../../../hooks/useShowContentPartner";
import useShowTannenbaum from "../../../hooks/useShowTannenbaum";
import { showMissingProfileInformation } from "../../more/MoreScreen";
import { getDisplayName } from "../../more/profile/UserComponent";
import { SearchBar } from "../components/SearchBar";
import { AllContentCard } from "./components/AllContentCardComponent";
import { HotFeaturesComponent } from "./components/HotFeaturesComponent";
import { MaintenanceInfoComponent } from "./components/MaintenanceInfoComponent";
import PersonalKPIs from "./components/PersonalKpiComponent";
import { TippsContainer } from "./components/TippsContainerComponent";

function normalizeVersion(version) {
  const segments = version.split(".");
  while (segments.length < 3) {
    segments.push("0");
  }
  return segments.join(".");
}

function checkCurrentVersion(data) {
  if (!data || Platform.OS === "web") return false;
  const currentVersion = DeviceInfo.getVersion();

  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "version_check",
    "HomeScreen",
    "VersionCheck",
    { currentVersion, requiredVersion: data?.requiredVersion }
  );

  const requiredVersion = data?.requiredVersion;

  // Normalize both versions to the same format
  const normalizedCurrentVersion = normalizeVersion(currentVersion);
  const normalizedRequiredVersion = normalizeVersion(requiredVersion);

  // Check if the current version is greater than or equal to the required version
  const isOutdated = semver.gte(
    normalizedCurrentVersion,
    normalizedRequiredVersion
  );

  return !isOutdated;
}

export default function HomeScreen() {
  const maintenanceWork = useFeatureFlagWithPayload("maintenance");
  const hasTannenbaum = useShowTannenbaum();

  const showContentPartner = useShowContentPartner();

  const selectedEnterprise = useSelector(selectEnterprise);
  const allData = useSelector(selectAllContent);
  const versionRequirements = useSelector(selectVersionRequirements);

  useEffect(() => {
    const isOutdated = checkCurrentVersion(versionRequirements?.system);

    if (isOutdated) {
      navigation.navigate("UpdateEnforcerScreen");
    }
  }, [versionRequirements]);

  useEffect(() => {
    if (selectedEnterprise?.default_language !== "en") {
      i18n.locale = "de";
    }
  }, [selectedEnterprise]);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const currentUser = useSelector(selectCurrentUser);

  const navigationIntent = useSelector(selectNavigationIntent);

  const homeScreenContent = allData?.homeScreenContent?.content;

  const loading = useSelector(selectIsLoading);

  const [userSourceModalVisible, setUserSourceModalVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (showMissingProfileInformation(currentUser)) {
        dispatch(addToUserCreationObject(currentUser));
        navigation.navigate("EnterMissingInformationModalScreen");
      }
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [currentUser]);

  useEffect(() => {
    let timer;
    if (!loading && navigationIntent) {
      timer = setTimeout(() => {
        navigateToNextCMSPage(navigationIntent, navigation);
        dispatch(setNavigationIntent(""));
      }, 250);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [loading, navigationIntent]);

  useFocusEffect(() => {
    dispatch(setShowNavbar(true));
  });

  async function checkPushNotificationSubscription() {
    if (Platform.OS === "web") return;

    async function getPreviousState(key) {
      const value = (await AsyncStorage.getItem(key)) ?? "true";
      return value === "true";
    }

    const prevUpdatesEnabled = await getPreviousState("updatesEnabled");
    const prevMotivationEnabled = await getPreviousState("motivationEnabled");
    const previousPreferences: string[] = [];
    if (prevUpdatesEnabled) previousPreferences.push("UPDATES");
    if (prevMotivationEnabled) previousPreferences.push("MOTIVATIONAL");

    if (
      !currentUser?.pn_preference ||
      JSON.stringify(
        currentUser?.pn_preference.filter((item) => item !== "PERSONALIZED")
      ) !== JSON.stringify(previousPreferences)
    ) {
      if (!selectedEnterprise && currentUser?.firstname)
        navigation.navigate("PushNotificationScreen", {
          isFromSettings: true,
          preventClosing: true,
        });
    }
  }

  async function load() {
    await loadAllData(selectedEnterprise?.internal_customer_id);
  }

  async function openingActions() {
    checkPushNotificationSubscription();
  }

  useEffect(() => {
    load();
    openingActions();
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "home_screen_opened",
        "HomeScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection("Home"));
      (async () => {
        if (
          getResultPagesCount() > 3 &&
          (await handleOneTimeAction("show_user_source"))
        ) {
          setUserSourceModalVisible(true);
        }
      })();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const [selected, setSelected] = useState(false);

  const [refreshing, setRefreshing] = useState(false);
  const [reloading, setReloading] = useState(false);

  const hasHistory = currentUser?.result_page_history?.history?.length > 0;
  const scrollViewTopMargin = useScrollTopMargin();

  const greeting = useGreeting();

  const onRefresh = async () => {
    setRefreshing(true);

    setReloading(true);
    await loadAllData(selectedEnterprise?.internal_customer_id);
    setReloading(false);
    setTimeout(() => {
      setRefreshing(false);
    }, 1250);
  };

  if (loading) {
    return (
      <View
        style={{
          backgroundColor: staticColors[colorScheme].background,
          flex: 1,
        }}
      >
        <FullScreenProgressBar />
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        overflow: "visible",
        backgroundColor: staticColors[colorScheme].background,
      }}
    >
      <UserSourceModal
        open={userSourceModalVisible}
        onClose={() => setUserSourceModalVisible(false)}
      />
      <ScrollView
        nestedScrollEnabled
        style={{
          //overflow: Platform.OS !== "web" ? "visible" : "hidden",
          flex: 1,
          //overflow: "visible",
        }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            tintColor="transparent"
            colors={["transparent"]}
            style={{ backgroundColor: staticColors[colorScheme].transparent }}
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
        onScrollBeginDrag={() => {
          if (refreshing) {
            setRefreshing(false);
          }
        }}
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
        contentContainerStyle={{
          padding: Sizes.defaultContainerPadding,
          flexGrow: 1,
          paddingBottom: 140,
          paddingTop: scrollViewTopMargin,
          backgroundColor: staticColors[colorScheme].background,
        }}
      >
        <DefaultHeader height={150} bottomOffset={-26} />

        {refreshing && Platform.OS !== "android" && (
          <View
            style={{
              position: "absolute",
              top: 120,
              opacity: 0.5,
              zIndex: 10,
              alignSelf: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {reloading ? (
              <DefaultActivityIndicator />
            ) : (
              <Text
                style={{
                  color: staticColors[colorScheme].text,
                  fontSize: 14,
                  marginRight: 8,
                }}
              >
                Gerade aktualisiert
              </Text>
            )}
            {!reloading && (
              <Ionicons
                name="checkmark-circle"
                size={20}
                color={staticColors[colorScheme].text}
              />
            )}
          </View>
        )}
        <View
          style={{
            zIndex: 0,
            elevation: 2,
            gap: 12,
            alignItems: "flex-start",
            paddingBottom: 16,
          }}
        >
          <Text
            style={{
              fontSize: Sizes.sectionHeader,
              alignSelf: "stretch",
              fontWeight: "500",
            }}
          >
            {greeting},{" "}
            <Text style={{ fontWeight: "bold" }}>
              {getDisplayName(currentUser, selectedEnterprise)}
            </Text>
            !
          </Text>

          <SearchBar
            action={() => {
              navigation.navigate("GlobalSearchScreen");
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "global_search_selected",
                "HomeScreen",
                "SearchBar"
              );
            }}
            placeholder={i18n.t("default_search_placeholder")}
            origin={"Home"}
            {...{
              query,
              setQuery,
              selected,
              setSelected,
              filters,
              setFilters,
            }}
            style={{
              width: "100%",
              marginTop: Platform.OS === "web" ? 12 : 0,
            }}
          />
        </View>
        {maintenanceWork?.[1] &&
          getValueFromInteractions("hide_maintenance_info") !== "true" && (
            <MaintenanceInfoComponent info={maintenanceWork} />
          )}

        <View
          style={{
            gap: isLargerDevice() ? 48 : 38,
            marginTop: 16,
          }}
        >
          {showContentPartner && <PrimeSection />}

          {/* <SocialProofSlider {...{ currentUser, selectedEnterprise }} /> */}
          <View
            style={{
              gap: isLargerDevice() ? 32 : 24,
              //paddingTop: 16,
              paddingBottom: 32,
              borderBottomColor: "#bbb",
              borderBottomWidth: 0.3,
              // borderTopWidth: 0.2,
            }}
          >
            <HotFeaturesComponent />

            {hasHistory ? <RecentlyUsedSection /> : <RecommendationSection />}
          </View>

          {showContentPartner && <SatelliteSection />}

          <View
            style={{
              gap: isLargerDevice() ? 32 : 24,
              //paddingTop: 32,
              paddingBottom: 32,
              borderBlockColor: "#bbb",
              borderBottomWidth: 0.3,
              //borderTopWidth: 0.3,
            }}
          >
            <PersonalKPIs />
            <AllContentCard />

            <TippsContainer />
          </View>
          {/* <SearchBar style={{ marginBottom: 32 }} usable={false} /> */}

          {homeScreenContent?.showCustomSectionOne &&
            homeScreenContent.customSectionOneHeader && (
              <CustomCMSSection
                header={homeScreenContent.customSectionOneHeader}
                ids={homeScreenContent.customSectionOneIds}
              />
            )}

          {hasHistory ? <RecommendationSection /> : <RecentlyUsedSection />}
        </View>
      </ScrollView>
    </View>
  );
}
