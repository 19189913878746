import { Image, Text, TouchableOpacity, View } from "react-native";

import { useEffect } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import CustomModal from "./CustomModal";
import { useNavigation } from "@react-navigation/native";

export default function TannenbaumSuccessModal({ open, onClose }) {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  useEffect(() => {
    if (open)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "tannenbaum_info_modal_presented",
        "TannenbaumInfoModal",
        "presented"
      );
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Image
          source={require("../../../assets/images/tannenbaum/tannenbaum.png")}
          style={{
            width: 100,
            height: 100,
            alignSelf: "center",
            marginBottom: 12,
          }}
        />
        <Text
          style={{
            fontWeight: "500",
            fontSize: 20,
            marginBottom: 12,
            color: Colors[colorScheme].text,
          }}
        >
          Gefunden!
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            color: Colors[colorScheme].text,
          }}
        >
          Herzlichen Glückwunsch!
        </Text>

        <DefaultButton
          action={() => {
            onClose();
            navigation.navigate("TannenbaumWheelScreen");
          }}
          style={{ marginTop: 20 }}
          text="Geschenk öffnen"
          icon={null}
        />
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].accent,
        ...style,
      }}
    >
      <Text style={{ fontSize: 16, fontWeight: "500" }}>{text}</Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
