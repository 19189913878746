import { Ionicons } from "@expo/vector-icons";
import { appleAuth } from "@invertase/react-native-apple-authentication";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { CustomSignInButton } from "../LandingScreen";
import i18n from "../../../../../locales/i18n";

export function SignInWithAppleButton() {
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  async function onAppleButtonPress() {
    setLoading(true);
    try {
      const appleAuthRequestResponse = await appleAuth.performRequest({
        requestedOperation: appleAuth.Operation.LOGIN,
        requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
      });

      const credentialState = await appleAuth.getCredentialStateForUser(
        appleAuthRequestResponse.user
      );

      if (credentialState === appleAuth.State.AUTHORIZED) {
        console.log("APPLE AUTHORIZATION CODE", appleAuthRequestResponse);
        // Der Nutzer ist authentifiziert, sende den Authorization Code an deinen Server
        //TODO: check if email is in auth object
        navigation.navigate("RoleSelectionScreen", {
          authData: appleAuthRequestResponse,
        });
        //sendAuthCodeToServer(appleAuthRequestResponse, navigation);
      }
    } catch (e) {
      console.log("SOMETHING WENT WRONG WITH APPLE AUTHENTICATION", e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <CustomSignInButton
      loading={loading}
      provider={<Ionicons name="logo-apple" size={20} color="black" />}
      text={i18n.t("continue_with_apple")}
      onPress={onAppleButtonPress}
    />
  );
}
